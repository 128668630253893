import _forEach from "lodash-es/forEach";
import React, { useCallback } from 'react';
import { NotificationType, RequestFailedNotification } from '@/common/components/notification-banner';
import { useNotifications } from '@/common/hooks/notifications-hook';
import { useTranslation } from '@/common/hooks/translation-hook';
import { extractGraphQLErrorContext, logger } from '@/common/utils/logger';
import { jsx as ___EmotionJSX } from "@emotion/core";

function useUserError() {
  var _useNotifications = useNotifications(),
      show = _useNotifications.show;

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var showGeneralError = useCallback(function (key) {
    if (key === void 0) {
      key = 'general-error';
    }

    show({
      key: key,
      type: NotificationType.Error,
      primaryContent: function primaryContent() {
        return ___EmotionJSX(RequestFailedNotification, {
          message: t('generalError')
        });
      }
    });
  }, [show, t]);
  var showUserErrors = useCallback(function (errors, artworkId) {
    _forEach(errors, function (error) {
      logger.error(error.message, Object.assign({
        artworkId: artworkId
      }, error));
      show({
        key: "user-error-" + error.message,
        type: NotificationType.Error,
        primaryContent: function primaryContent() {
          return ___EmotionJSX(RequestFailedNotification, {
            message: error.message,
            displayableError: error
          });
        }
      });
    });
  }, [show]);
  var showApolloErrors = useCallback(function (_ref, artworkId) {
    var errors = _ref.graphQLErrors,
        message = _ref.message;

    _forEach(errors, function (error) {
      var parsedError = extractGraphQLErrorContext(error);
      logger.error(error.message, Object.assign({
        artworkId: artworkId
      }, parsedError));
      show({
        key: "api-error-" + error.message,
        type: NotificationType.Error,
        primaryContent: function primaryContent() {
          return ___EmotionJSX(RequestFailedNotification, {
            message: error.message,
            displayableError: parsedError
          });
        }
      });
    });

    if (!errors.length && message) {
      show({
        key: "network-error-" + message,
        type: NotificationType.Error,
        primaryContent: function primaryContent() {
          return ___EmotionJSX(RequestFailedNotification, {
            message: message
          });
        }
      });
    }
  }, [show]);
  return {
    showGeneralError: showGeneralError,
    showUserErrors: showUserErrors,
    showApolloErrors: showApolloErrors
  };
}

export { useUserError };